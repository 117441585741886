*::after,
*::before{
box-sizing: border-box;
}
.white{
    color: white;
}
.P-marginBottom{
   margin-bottom: 20px; 
}
.ringsButton{
    max-width: 5px;
    max-height: 10px;
    background-color: inherit;
}
.messageDiv{
    top: 70px;
    position: fixed;
    display: inline-block;
    background-color: rgb(118, 165, 47);
    border: #000 1px solid;
    padding: 5px 60px 5px 60px;
    z-index: 1;
}
.navbarimgdiv{
    display: none;
    justify-content: center;
}
.navbarLinkDiv{
    position: relative;
}
.navbarLink{
    text-decoration: none;
    color: white;
    position: absolute;
}
.navbarLink:hover{
    text-shadow: 2px 2px #7A78A4;
    transition: 0.2s;
}
.navbarLink:active{
    font-size: 13px;
}
.navbar{
    margin-left: 20%;
    margin-right: 25%;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
}
.navbarDiv{
    display: block;
    background-color: #7A78A4;
    border-radius: 0px 0px 15px 15px;
    box-shadow: 0px -25px 15px #272549 inset;
    margin: -7px 0px 30px -15px;
    width: 99vw;
    padding: 15px 20px 30px 0px;
}
.menuListLink{
    width: 200px;
}
.menuList{
    display: flex;
    flex-direction: column;
}
.strelkiButton{
    display: none;
}
.menuListDiv{
    margin-left: -10px;
    margin-right: 20px;  
}
.menuListInner{
    display: flex;
}

.kataloogOutlet{
    line-height: 20px;
    width: 77vw;
    border-radius: 15px;
    padding-left: 10px;
    background-color: #bbcdec;
}
.NavLinkMenu{
    margin-top: 10px;
    margin-left: -5px;
}
body{
    margin: 0px;
    background-color: #262261;
}
.root{
    position: relative;
}
.language{
    margin-top: -25px;
    position: absolute;
    right: 20px;
    background-color: #bbcdec;
    border-radius: 25px;
}
.AuthPageContainer{
    max-width: 800px;
    position: absolute;
    margin-top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.authPageInput{
    border-radius: 15px 15px 15px 15px;
    margin-bottom: 5px;
    margin-right: 5px;
    margin-top: 5px;
    background-color: #bbcdec;
}
.registerInputs{
    display: flex;
    flex-direction: column;
    max-width: 180px;
}
.registerButton{
    background-color: yellow;
    border: 1px yellow solid;
    border-radius: 15px;
    padding: 5px 20px 5px 20px;
}
.registerButtonDiv{
    display: flex;
    justify-content: center;
}
.adminLogo{
    position: absolute;
    max-width: 400px;
    max-height: 200px;
    right: 20%;
    top: 50px;
}
.logo{
    max-width: 162px;
    max-height: 90px;
    margin-bottom: 10px;
}
.logoHome{
    max-width: 500px;;
    max-height: 200px;
    margin: auto;
}
.container{
    height: 100vh;
    overflow-x:hidden;
    padding: 0px 5px 0px 5px;
}
.homeContainer{
    display: flex;
    margin: auto;
}
.menu{
    position: relative;
    margin: 5px;
}
.usualyTd:last-child{
    font-weight: normal;
}
.toografik{
    display: inline-block;
}
.TimeInputs{
    display: flex;
}
.inputName{
    color: rgb(251, 248, 248);
    margin-top: 5px;
    margin-bottom: 5px;
}
.inputsDiv{
    box-shadow: 1px 1px 10px 5px #000;
    background-color: #272549	;
    padding-left: 7px;
    border: #050606 1px solid;
    border-radius: 5%;
    margin-top: 10px;
    margin-bottom: 20px;
    padding-bottom: 10px;
}
.inputNameDate{
    max-width: 42px;
}
.inputInner{
    margin-right: 10px;
}
.aegInput{
    margin-right: 10px;
}
.inputsaeg{
    max-width: 72px;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none; 
}
.PhotoHolder{
    display: flex;
}
.photoAccepted{
    max-width: 100px;
    max-height: 85px;
}
.photo{
    width: 250px;
    height: 187.5px;
    background-color: hsl(0deg 0% 72%);
}
.photoDiv{
    position: relative;
    display: inline-block;
}
.acceptButton,
.denyButton{
    bottom: 10%;
    display: block;
    z-index: 1;
    position: absolute;
    max-width: 50px;
    max-height: 35px;
    border: none;
    background: transparent;
    cursor: pointer;
}
.acceptButton{
    left: 20%;
}
.denyButton{
    right: 20%;
}
.Buttonlogo{
    width: 50px;
    height: 35px;
}
.seletus{
    margin-top: 10px;
    height: 100px;
    width: 200px;
}
.pictureDiv{
    position: relative;
    display: inline-block;
}
.output{
    margin-top: 50px;
    max-width: 100vh;
}
.deleteButton{
    position: absolute;
    font-size: 110%;
    color: darkred;
    z-index: 1;
    right: 10px;
    width: 24px;
    height: 24px;
    border: none;
    background: transparent;
    cursor: pointer;
}
canvas{
    display: none;
}
.camera{
    max-height: 300px;
    position: relative;
}
.video{   
    max-width: 200px;
    max-height: 200px;
}
.submitButton{
    margin:0 5px 5px 100px;
}
.submitButton1{
    margin-left: 70px;
}
.submitButton2{
    margin-left: 128px;
}
.push_button{
    margin-top: 10px;
    position: absolute;
    text-align:center;
    color:#FFF;
    text-decoration:none;
    font-family:'Oswald', Helvetica;
    text-shadow:-1px -1px 0 #8B7222;
    background: #8B7222;
    border:1px solid #8B7222;
    border-radius:5px;
}
.push_button:hover{
    background: #C5B273;
    cursor: pointer;
}
.push_button:active {
    border:2px solid #050606;
  }
.adminPage{
    display: flex;
}
.outletPlace{
    position: relative;
}
.adminMenu{
    min-width: 150px;
    display: flex;
    flex-direction: column;
    margin-left: -5px;
    margin-right: 10px;
}
.adminLink{
    display: inline-flex;
    color:#FFF;
    border: 1px solid #7b6e6e;
    padding: 5px 10px 5px 10px;
    background-color: #3b3b3b;
    text-decoration: none;
}
.adminLink:hover{
    cursor: pointer;
    box-shadow: 0px -25px 15px #585353 inset
}
.active{
    color: #8B7222;
}
.dropButton{
    flex-direction: column-reverse;
}
.dropButton1{
    flex-direction: column-reverse;
}
.nameDiv{
    font: bold italic 15px/1.5 Arial, sans-serif;
    white-space: nowrap;
    margin-bottom: -40px;
    color: #FFF;
}
.buttonDiv{
    padding-bottom: 30px;
    display: flex;
}
.selection{
    display: flex;
}
.h5{
    margin: 10px;
    color: #FFF;
}
.buttonsDiv{
    height: 10px;
}
.wurthPhoto{
    display: inline-flex;
    height: 300px;
    max-width: 300px;
    margin-right: 20px;
}
.wurthdiv{
    display: flex;
}
.variable{
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 7px;
    margin-right: -2px;
    width: 7px;
    height: 7px;
    background-color: red;
    border-radius: 50%;
    animation-name: variableAnimation;
    animation-duration: 1.5s;
    animation-fill-mode: none;
    animation-iteration-count: infinite;
    animation-timing-function: ease;
}
@keyframes variableAnimation{
    from{
        box-shadow: 0 0 0 0px rgb(248 129 129);
    }
    to{
        box-shadow: 0 0 0 4px rgb(248 129 129);
    }
}

@media (max-width: 800px){
    .adminLogo{
        display: none;
    }
    .logoHome{
        max-width: 300px;;
        max-height: 2150px;
        margin: auto;
    }
    .menuList{
        position: fixed;    
    }
    .strelkiButton{
        display: block;
        background-color: inherit;
        border: none;
        position: fixed; 
        z-index: 1;
        opacity: .4;
        top: 48px;
    }
    .strelkiButton:hover{
        opacity: 1;
    }
    .strelki{
        max-width: 30px;
        max-height: 20px; 
    }
    .kataloogOutlet{
        width: 100vw;
    }
}
@media (max-width: 650px){
    .container{
        padding-left: 1px;
    }
    .logoHome{
        display: none;
    }
    .navbarimgdiv{
        display: grid;
    }
    .navbarimg{
        border-radius: 50%;
        box-shadow: 5px 5px 5px black;
    }
    .navbarimg:active{
        box-shadow: none;
    }
    .navbar{
        display: none;
        position: absolute;
        margin: -50px 0px 0px 15px;
        z-index: 1;
    }
    .navbarLink{
        display: grid;
        margin-top: 10px;
        align-content: stretch;
        width: 90%;
        height: 70%;
    }
    .navbarLinkDiv{
        background-color: #7A78A4;
        box-shadow: 0px -25px 15px #272549 inset;
        margin-left: -7px;
        padding-left: 10px;
        border: 1px black solid;
        width: 200px;
        height: 40px;
    }
    .navbarLinkDiv:active{
        background-color: #272549;
        box-shadow: 0px -25px 15px #7A78A4 inset;
    }
    .adminMenu{
        margin-left: 0px;
    }
}
@media (max-width: 550px){
    .wurthdiv{
        display: flex;
        flex-direction: column;
    }
    .strelkiButton{
        top: 90px;
    }
    .strelki{
        max-width: 40px;
        max-height: 30px; 
    }
}