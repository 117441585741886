.red{
  background-color: #e45353;
}
.yellow{
  background-color: #d3d34d;
}
.alarm{
  margin-top: 20px;
  color: red;
  font-size: larger;
}
.hiddenRow{
  opacity: 0.8;
}
.ferstButton{
  padding: 1px 2px 1px 2px;
  background-color: inherit;
  border: none;
}
.ButtonsHiddenDiv{
  display: none;
}
.ButtonsHiddenDiv1{
  position: absolute;
  margin-top: -19px;
  margin-left: -2px;
  opacity: 0.8;
  max-width: 30px;
  z-index: 1;
}
.hiddenButtons{
  width: 70px;
}
table {
  overflow-x: scroll;
  display: table;
  border-collapse: collapse;
}
.nameDiv{
  position: absolute;
  left: 20;
  margin-top: -20px;
}
.graafikuP{
  margin: 3px;
}
.graafikuPDiv{
  max-width: 200px;
  margin-left: -155px;
  display: flex;
  flex-direction: column-reverse ;
  background-color: #c4c0c0;
}
.table{
  overflow-x: scroll;
}
.TableInner{
  position: relative;
  display: grid;
}
.kutusTableInner{
  position: relative;
}
.carNr :not(th){
  font-size: 13px;
}
td:last-child{
  font-weight: bold;
}
td,
th {
  border: 1px solid black;
  padding: 3px;
  text-align: center;
}
td{
  background-color: rgb(251, 248, 248);
}
th{
  font-size: 14px;
  font-weight: bold;
  background-color: #c4c0c0;
  width: 50px;
}
.esimene{
  height: 23px;
  width: 100px;
  margin-left: -155px;
  font-weight: bold;
  background-color: #c4c0c0;
  border: 1px solid black;
  padding: 2px 2px 0px 2px;
  text-align: center;
}
.CreateTable{
  margin-top: 50px;
  margin-left: -50px;
  overflow-x: scroll;
}
.kutus{
  margin-top: 10px;
  max-height: 20px
}
.kuuGrafikTable{
  margin-left: -120px;
}
.kuuGrafikTd{
  white-space: nowrap;
  position: relative;
  border: 1px black solid;
  padding: 2px;
  margin-left: 100px;
}
.kuuGrafikHeading2{
  white-space: nowrap;
}
.kutuseTh:first-child{
  position: relative; 
}
.inputs{
  text-transform: lowercase;
}
.thInput{
  background-color: inherit;
  outline: none;
  width: 100px;
}
.kuuGrafikInput{
  font-size: 13px;
  padding: 1px;
  border: none;
  max-width: 50px;
  text-align: center;
  background-color: inherit;
}
.kuuGrafikInput:focus-visible{
  outline: none;
}
.tourInput{
  max-width: 20px;
  max-height: 14px;
}
tr th:first-child{
  position: absolute;
  left: 0;
  margin-top: -0.4px;
  margin-right: 0px;
}
.marginleft{
  margin-left: 150px;
}
.marginTop{
  margin-top: 40px;
}
.ferst{
  max-height: 20px;
  min-height: 19px;
  width: 150px;
  margin-left: -155px;
  justify-content: space-between;
  display: flex;
  font-weight: bold;  
  background-color: #c4c0c0;
  border: 1px solid black;
  padding: 2px 2px 2px 2px;
}
.saturdayDiv{
  display: flex;
  position: absolute;
  top:50%;
  left:50%;
  transform:translate(-50%, -50%);
  background-color: rgba(173, 168, 168, 0.8);
  padding-right: 20px;
}
.saturdayComponent__button{
  position: absolute;
  font-weight: 900;
  border: none;
  cursor: pointer;
  left: 100%;
  transform: translateX(-100%);
  background-color: rgba(173, 168, 168, 0);   
}
.saturdayComponent__li{
  list-style: none;
  font-size: larger;
}
.saturdayDecoration{
  text-decoration: underline;
  text-decoration-color: rgb(164, 0, 0);
  cursor: pointer;
}
@media (max-width: 650px){
  .marginleft{
    margin-left: 154px;
  }
}
